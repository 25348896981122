<template>
  <div id="app">
    <template v-if="loading">
      <LoginBg :pageTitle="$t('login.title')" :isBackGroundOnly=true />
      <v-row
        width="100%"
        class="fill-height ma-0"
        align="center"
        justify="center">
        <v-progress-circular
          indeterminate
          color="#FFD600"
          width="6"
          size="64"
        ></v-progress-circular>
      </v-row>
    </template>

    <template v-else>
    <LoginBg :pageTitle="$t('login.title')" :isShowRegister=true />
    <br />
    <v-layout justify-center>
       <!-- <v-form @submit.prevent="submit"></v-form> -->
      <v-form>
        <div class="mb-5" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="username"
            type="text"
            autocomplete="username"
            :label="$t('login.username')"
            :placeholder="$t('login.username')"
            append-icon="person"
            filled
            hide-details
            clearable
          ></v-text-field>
        </div>
        <div class="mb-0" style="width: 300px">
          <v-text-field
            class="text_input"
            v-model="password"
            :label="$t('login.password')"
            :placeholder="$t('login.password')"
            :append-icon="isShowPassword ? 'visibility' : 'visibility_off'"
            @click:append="isShowPassword = !isShowPassword"
            :type="isShowPassword ? 'text' : 'password'"
            autocomplete="password"
            filled
            hide-details
            clearable
          />
        </div>
        <br />
        <!-- <div class="mb-5" style="width: 300px">
          <v-row>
            <v-col cols="12">
              <div style="margin-top: 8px; text-align: right;">
                <span class="link--text" style="cursor: pointer;" @click="forgotPasswordClick">{{ $t('login.forgotpassword') }}</span>
              </div>
            </v-col>
          </v-row>
        </div> -->
      </v-form>
    </v-layout>

    <v-layout justify-center>
      <v-btn rounded color="#3949AB darken-4" width="300" dark @click="loginClick" :disabled="!username || !password">{{ $t('login.login_button') }}</v-btn>
    </v-layout>
    <br />

    </template>

    <ShowOverlay 
      v-model="isShowOverlay">
    </ShowOverlay>
    <ShowMessage 
      v-model="isShowMessage" 
      :msg="showmessage_msg"
      :width="showmessage_width"
      :callBack="showmessage_callback">
    </ShowMessage>

  </div>
</template>

<script>
import { server } from "@/services/constants";
import LoginBg from "@/components/LoginBg";
import ShowOverlay from "@/components/ShowOverlay";
import ShowMessage from "@/components/ShowMessage";
import api from "@/services/api";

export default {
  name: "Login",
  data() {
    return {
      username: '',
      password: '',
      isShowPassword: false,
      // remember: false,
      loading: false,
      isShowOverlay: false,
      isShowMessage: false,
      showmessage_msg: "",
      showmessage_width: 400,
      showmessage_callback: null
    };
  },
  components: {
    LoginBg,
    ShowOverlay,
    ShowMessage
  },
  // async mounted() {
  //   if (this.$store.state.isLogged) {
  //     try {
  //       this.loading = true;
  //       await this.gotoHomePage();
  //     } catch (error) {
  //       this.showMessage(error.toString(), 400);
  //     } finally {
  //       // this.loading = false;
  //     }
  //   }
  // },
  methods: {
    showMessage(showmessage_msg, showmessage_width) {
        this.showmessage_msg = showmessage_msg;
        this.showmessage_width = showmessage_width;
        this.isShowMessage = true;
    },
    onShowMessageCallBack() {
      // this.$router.back();
      this.$store.dispatch("doLogout");
    },
    forgotPasswordClick() {
      this.$router.push(`/forgotpassword`);
    },
    async doLoginWithPassword() {
      try {
        this.showmessage_callback = null;
        if (this.username?.trim()=="" || this.password?.trim()=="") {
          this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_user_or_password}`, 400);
          return;
        }
        this.isShowOverlay = true;
        const response = await api.login(this.username, this.password);
        // alert(JSON.stringify(response));
        if (response?.userCode ?? "" != "") {
          localStorage.setItem(server.USERNAME, response.userCode);
          localStorage.setItem(server.ACCESS_TOKEN, response.accessToken);
          localStorage.setItem(server.ID_TOKEN, response.idToken);
          localStorage.setItem(server.REFRESH_TOKEN, response.refreshToken);
          localStorage.setItem(server.EXPIRES_IN, response.expiresIn);
          localStorage.setItem(server.EXPIRES_TIME, this.getExpireTime(response.expiresIn - 120));
          // alert(JSON.stringify(response));
          const result = await api.refreshUserInfo();
          // alert(JSON.stringify(result));
          if (result?.userCode ?? "" != "") {
            localStorage.setItem(server.USER_INFO, JSON.stringify(result));
            this.$store.commit("SET_LOGGED_IN");

            const { redirect } = this.$route.query;
            if (redirect) {
              this.$router.replace(redirect);
            }
            else {
              this.$router.replace("/home", () => {});
            }
            return;
          }
        }
        this.showmessage_callback = this.onShowMessageCallBack;
        this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_user_or_password}`, 400);

        // this.$store.dispatch("doLogout");
        // if (this.$store.state.isLogged) {
        //   let token = localStorage.getItem(server.USERNAME);
        //   await api.refreshUserInfo(token.toString());

        //   const { redirect } = this.$route.query;
        //   if (redirect) {
        //     this.$router.replace(redirect);
        //   }
        //   else
        //     this.$router.replace("/home", () => {});
        // }
        // else
        //   this.showMessage(`${this.$i18n.getLocaleMessage(this.$i18n.locale).message.invalid_user_or_password}`, 400);
      } catch (error) {
        this.showMessage(error.response.data, 400);
      }
      finally {
        setTimeout(() => {
          this.isShowOverlay = false;
        }, 100);
      }
    },
    async loginClick() {
      try {
        await this.doLoginWithPassword();
      } catch (error) {
        this.showMessage(error.toString(), 400);
      }
    },
    getExpireTime(sec) {
      const t = new Date();
      if (sec > 0)
          t.setSeconds(t.getSeconds() + sec);
      return parseInt(t / 1000); //utc datetime
    }
  },
};
</script>

<style scoped>
.text_input {
  background-color: #FAFAFA;
  color: #000000
}
.black--text /deep/ label {
  font-size: x-small;
  color: #ffffff;
}
.link--text {
  font-size: x-small;
  color: #FFFFFF;
  position: relative;
}
</style>